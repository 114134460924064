.addPromoCodeText {
  color: #003595;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

@media all and (min-width: 1020px) {
  .promoCodeInputWrapper {
    display: flex;
  }
  .promoCodeInputWrapper > button {
    float: right;
    height: 3rem;
    margin-top: 1rem;
  }
}

@media all and (max-width: 1020px) {
  .promoCodeInputWrapper > button {
    margin: 8px 0 0 0;
  }
}
