/* A space for common css shared across multiple components */

.form-control:focus {
  border: 1px solid #003595;
  box-shadow: none !important;
}

.header_footer_layout_body {
  padding: 0 18px;
  // height: 100%;
}
